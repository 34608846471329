import { useState } from 'react';

import { IFaqBlock } from 'epromo-lib-cms/types/payload-types';
import { TinyMceContent } from '@components/atoms/TinyMceContent';

import { FaqListItem } from './FaqListItem';

export const FaqBlock = ({ titleAndDescription, items }: IFaqBlock) => {
  const [clickedFaqItem, setClickedFaqItem] = useState<string | undefined>(
    items[0].id
  );

  return (
    <div>
      <TinyMceContent className="h1" content={titleAndDescription} />

      <div className="flex flex-col gap-2 w-full">
        {items?.map((item, idx) => {
          return (
            <FaqListItem
              question={item.question}
              key={item.id}
              isLast={idx === items.length - 1}
              isOpen={item.id === clickedFaqItem}
              handleToggle={() => {
                if (clickedFaqItem === item.id) setClickedFaqItem(undefined);
                else setClickedFaqItem(item.id);
              }}
            >
              <TinyMceContent content={item.answer} />
            </FaqListItem>
          );
        })}
      </div>
    </div>
  );
};
