import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Disclosure, Transition } from '@headlessui/react';

import { PlusMinusAnimated } from '@components/atoms/PlusMinusAnimated';

export const FaqListItem = ({
  children,
  question,
  isLast,
  isOpen,
  handleToggle,
}: {
  question: string;
  children: ReactNode;
  isLast: boolean;
  isOpen: boolean;
  handleToggle: () => void;
}) => {
  const boxShadow = '0px 8px 27px 0px rgba(167, 170, 193, 0.09)';

  return (
    <Disclosure>
      <div
        className={twMerge(
          'w-full p-8 rounded-2xl bg-white',
          isOpen ? 'bg-neutral-100' : 'bg-white',
          'transition-all'
        )}
        style={{ boxShadow: isOpen ? undefined : boxShadow }}
      >
        <button
          key={question}
          onClick={handleToggle}
          className={twMerge(
            'w-full font-bold text-base text-start flex justify-between'
          )}
        >
          {question}
          <PlusMinusAnimated isOpen={isOpen} />
        </button>

        <Transition
          show={isOpen}
          className="overflow-hidden "
          enter={twMerge(
            'transition transition-[max-height] duration-1000',
            'ease-[cubic-bezier(0.55, 0, 0.1, 1)]'
          )}
          enterFrom="transform max-h-0"
          enterTo="transform max-h-[1030px]"
          leave={twMerge(
            'transition transition-[max-height] duration-500',
            'ease-[cubic-bezier(0.55, 0, 0.1, 1)]'
          )}
          leaveFrom="transform max-h-[1030px]"
          leaveTo="transform max-h-0"
        >
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </Transition>
      </div>
    </Disclosure>
  );
};
