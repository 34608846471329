import { Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

export type PlusMinusAnimatedProps = {
  className?: string;
  isOpen: boolean;
};

export function PlusMinusAnimated({
  isOpen,
  className,
}: PlusMinusAnimatedProps) {
  return (
    <span className={twMerge('relative h-6 w-6', className)}>
      <Transition
        className="absolute h-6 w-6"
        show={isOpen}
        enter="transition transform duration-150 -rotate-90"
        enterFrom="-rotate-90 opacity-0"
        enterTo="opacity-100 rotate-0"
        leave="transition transform duration-150 rotate-0"
        leaveFrom="opacity-100 rotate-0"
        leaveTo="-rotate-90 opacity-0"
      >
        <MinusIcon className="h-6 w-6" />
      </Transition>
      <Transition
        className="absolute h-6 w-6"
        show={!isOpen}
        enter="transition transform duration-150 rotate-0"
        enterFrom="rotate-0 opacity-0"
        enterTo="opacity-100 -rotate-90"
        leave="transition transform duration-150 -rotate-90"
        leaveFrom="opacity-100 -rotate-90"
        leaveTo="rotate-0 opacity-0"
      >
        <PlusIcon className="h-6 w-6" />
      </Transition>
    </span>
  );
}
