import { twMerge } from 'tailwind-merge';

export type TinyMceContentProps = {
  className?: string;
  content: string;
};

export function TinyMceContent({ className, content }: TinyMceContentProps) {
  return (
    <div
      className={twMerge('tinymce', className)}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
}
