import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

import {
  IAppAddBlock,
  ICardSliderBlock,
  ITabbedCarouselBlock,
  IServicesBlock,
  ITabbedPostersBlock,
  IVideoBlock,
  IBannerCarouselBlock,
  INewsBlock,
  IBannerTextLinksBlock,
  ILinksBlock,
  IImageBlock,
  IMediaTitleBlock,
  IOneColumnTextBlock,
  IDividerBlock,
  ITextImageSplitScreenBlock,
  ITextImageInFrameBlock,
  IPublicationsGridBlock,
  IPageTitleBlock,
  IPublicationsSliderBlock,
  IStoreLocationsBlock,
  IContactsBlock,
  IFaqBlock,
  IRoundImageListBlock,
  ITwoColumnsTextBlock,
  IPhotoGalleryBlock,
  IImageTitleHalfHalfBlock,
  IExclusiveSectionsSlider,
  IHtmlBlock,
  IWarehouseCertsBlock,
  IContactsMiniMapBlock,
  IPublicationsCatalogsBlock,
  IPublicationsCatalogsSliderSection,
  IPromoProductsBlock,
  IAdsTabsBlock,
  IAdsContactsBlock,
  INewsLetterBlock,
} from 'epromo-lib-cms/types/payload-types';
import { FaqBlock } from '@components/molecules/FaqBlock/FaqBlock';
import { IDeviceInfo } from 'epromo-types';

const NewsLetterSection = dynamic(
  () =>
    import('@components/organisms/NewsLetterSection/NewsLetterSection').then(
      (mod) => mod.NewsLetterSection
    ),
  {
    loading: () => <div></div>,
  }
);

const ServicesSection = dynamic(
  () =>
    import('@components/molecules/ServicesSection').then(
      (mod) => mod.ServicesSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const TabbedCarouselSection = dynamic(
  () =>
    import('@components/molecules/TabbedCarouselSection').then(
      (mod) => mod.TabbedCarouselSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const CardSliderSection = dynamic(
  () =>
    import('@components/molecules/CardSliderSection').then(
      (mod) => mod.CardSliderSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const BannerCarousel = dynamic(
  () =>
    import('@components/molecules/BannerCarousel').then(
      (mod) => mod.BannerCarousel
    ),
  {
    loading: () => <p>...</p>,
  }
);

const VideoBlockSection = dynamic(
  () =>
    import('@components/molecules/VideoBlockSection').then(
      (mod) => mod.VideoBlockSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const BannerTextLinksSection = dynamic(
  () =>
    import('@components/molecules/BannerTextLinksSection').then(
      (mod) => mod.BannerTextLinksSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const AppAddSection = dynamic(
  () =>
    import('@components/molecules/AppAddSection').then(
      (mod) => mod.AppAddSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const NewsSection = dynamic(
  () =>
    import('@components/molecules/NewsSection').then((mod) => mod.NewsSection),
  {
    loading: () => <p>...</p>,
  }
);

const TabbedPosterSection = dynamic(
  () =>
    import('@components/molecules/TabbedPosterSection').then(
      (mod) => mod.TabbedPosterSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const LinksSection = dynamic(
  () =>
    import('@components/molecules/LinksSection').then(
      (mod) => mod.LinksSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const ImageSection = dynamic(
  () =>
    import('@components/molecules/ImageSection').then(
      (mod) => mod.ImageSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const OneTextColumnSection = dynamic(
  () =>
    import('@components/molecules/OneTextColumnSection').then(
      (mod) => mod.OneTextColumnSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const TextImageSplitScreen = dynamic(
  () =>
    import('@components/molecules/TextBlocks/TextImageSplitScreen').then(
      (mod) => mod.TextImageSplitScreen
    ),
  {
    loading: () => <p>...</p>,
  }
);

const MediaTitleSection = dynamic(
  () =>
    import('@components/molecules/MediaTitleSection').then(
      (mod) => mod.MediaTitleSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const TextImageInFrame = dynamic(
  () =>
    import('@components/molecules/TextBlocks/TextImageInFrame').then(
      (mod) => mod.TextImageInFrame
    ),
  {
    loading: () => <p>...</p>,
  }
);

const DividerSection = dynamic(
  () =>
    import('@components/molecules/DividerSection').then(
      (mod) => mod.DividerSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PublicationsGrid = dynamic(
  () =>
    import('@components/organisms/PublicationsGrid').then(
      (mod) => mod.PublicationsGrid
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PageTitleBlock = dynamic(
  () =>
    import('@components/molecules/PageTitleBlock').then(
      (mod) => mod.PageTitleBlock
    ),
  {
    loading: () => <div></div>,
  }
);

const PublicationsSliderSection = dynamic(
  () =>
    import('@components/organisms/PublicationsSliderSection').then(
      (mod) => mod.PublicationsSliderSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const StoreLocations = dynamic(
  () =>
    import('@components/organisms/StoreLocations').then(
      (mod) => mod.StoreLocations
    ),
  {
    loading: () => <p>...</p>,
  }
);

const RoundImageList = dynamic(
  () =>
    import('@components/molecules/RoundImageList').then(
      (mod) => mod.RoundImageList
    ),
  {
    loading: () => <p>...</p>,
  }
);

const TextTwoColumns = dynamic(
  () =>
    import('@components/molecules/TextBlocks/TextTwoColumns').then(
      (mod) => mod.TextTwoColumns
    ),
  {
    loading: () => <p>...</p>,
  }
);

const HtmlBlock = dynamic(
  () =>
    import('@components/molecules/HtmlBlock/HtmlBlock').then(
      (mod) => mod.HtmlBlock
    ),
  {
    loading: () => <p>...</p>,
  }
);

const ContactsBlock = dynamic(
  () =>
    import('@components/molecules/ContactsBlock').then(
      (mod) => mod.ContactsBlock
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PhotoGalleryBlock = dynamic(
  () =>
    import('@components/molecules/PhotoGalleryBlock').then(
      (mod) => mod.PhotoGalleryBlock
    ),
  {
    loading: () => <p>...</p>,
  }
);

const ImageColorTitleHalfHalf = dynamic(
  () =>
    import('@components/molecules/ImageColorTitleHalfHalf').then(
      (mod) => mod.ImageColorTitleHalfHalf
    ),
  {
    loading: () => <p>...</p>,
  }
);

const ExclusiveSectionsSlider = dynamic(
  () =>
    import('@components/organisms/ExclusiveSectionsSlider').then(
      (mod) => mod.ExclusiveSectionsSlider
    ),
  {
    loading: () => <p>...</p>,
  }
);

const WarehouseCertsBlock = dynamic(
  () =>
    import('@components/molecules/WarehouseCertsBlock').then(
      (mod) => mod.WarehouseCertsBlock
    ),
  {
    loading: () => <p>...</p>,
  }
);

const ContactsMiniMapBlock = dynamic(
  () =>
    import('@components/molecules/ContactsMiniMapBlock').then(
      (mod) => mod.ContactsMiniMapBlock
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PublicationsCatalogsSection = dynamic(
  () =>
    import('@components/molecules/PublicationsCatalogsSection').then(
      (mod) => mod.PublicationsCatalogsSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PublicationsCatalogsSliderSection = dynamic(
  () =>
    import('@components/organisms/PublicationsCatalogsSliderSection').then(
      (mod) => mod.PublicationsCatalogsSliderSection
    ),
  {
    loading: () => <p>...</p>,
  }
);

const PromoProductsSection = dynamic(
  () =>
    import('@components/organisms/PromoProductsSection').then(
      (mod) => mod.PromoProductsSection
    ),
  {
    loading: () => <div></div>,
  }
);

const AdsTabs = dynamic(
  () => import('@components/organisms/AdsTabs').then((mod) => mod.AdsTabs),
  {
    loading: () => <div></div>,
  }
);

const AdsContactsBlock = dynamic(
  () =>
    import('@components/molecules/ContactsBlock/AdsContactsBlock').then(
      (mod) => mod.AdsContactsBlock
    ),
  {
    loading: () => <div></div>,
  }
);

export type Block =
  | IBannerCarouselBlock
  | ITabbedPostersBlock
  | ICardSliderBlock
  | IVideoBlock
  | IAppAddBlock
  | ITabbedCarouselBlock
  | IServicesBlock
  | INewsBlock
  | IBannerTextLinksBlock
  | IImageBlock
  | ILinksBlock
  | IMediaTitleBlock
  | IOneColumnTextBlock
  | IDividerBlock
  | ITextImageSplitScreenBlock
  | ITextImageInFrameBlock
  | IPublicationsGridBlock
  | IPageTitleBlock
  | IPublicationsSliderBlock
  | IStoreLocationsBlock
  | IContactsBlock
  | IRoundImageListBlock
  | ITwoColumnsTextBlock
  | IPhotoGalleryBlock
  | IImageTitleHalfHalfBlock
  | IExclusiveSectionsSlider
  | IHtmlBlock
  | IWarehouseCertsBlock
  | IContactsMiniMapBlock
  | IPublicationsCatalogsBlock
  | IPublicationsCatalogsSliderSection
  | IPromoProductsBlock
  | IAdsTabsBlock
  | IAdsContactsBlock
  | IFaqBlock
  | INewsLetterBlock;

export const ResolveCmsComponent = ({
  block,
  deviceInfo,
}: {
  block: Block;
  deviceInfo?: IDeviceInfo;
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  if (block['__typename'] === 'Bannercarouselblock') {
    return renderComponent(block, deviceInfo);
  }
  return (
    <div ref={ref} className={!inView ? 'min-h-screen' : ''}>
      {inView ? renderComponent(block, deviceInfo) : null}
    </div>
  );
};

export const renderComponent = (block: Block, deviceInfo?: IDeviceInfo) => {
  switch (block['__typename']) {
    case 'Bannercarouselblock': {
      return (
        <BannerCarousel
          key={block.id}
          {...(block as IBannerCarouselBlock)}
          deviceInfo={deviceInfo}
        />
      );
    }
    case 'Tabbedpostersblock': {
      return (
        <TabbedPosterSection
          key={block.id}
          {...(block as ITabbedPostersBlock)}
        />
      );
    }
    case 'Cardsliderblock': {
      return (
        <CardSliderSection key={block.id} {...(block as ICardSliderBlock)} />
      );
    }
    case 'Tabbedcarouselblock': {
      return (
        <TabbedCarouselSection
          key={block.id}
          {...(block as ITabbedCarouselBlock)}
        />
      );
    }
    case 'Videoblock': {
      return <VideoBlockSection key={block.id} {...(block as IVideoBlock)} />;
    }
    case 'Appaddblock': {
      return <AppAddSection key={block.id} {...(block as IAppAddBlock)} />;
    }
    case 'Servicesblock': {
      return <ServicesSection key={block.id} {...(block as IServicesBlock)} />;
    }
    case 'Newsletter': {
      return (
        <NewsLetterSection key={block.id} {...(block as INewsLetterBlock)} />
      );
    }
    case 'Newsblock': {
      return <NewsSection key={block.id} {...(block as INewsBlock)} />;
    }
    case 'Imageblock': {
      return <ImageSection key={block.id} {...(block as IImageBlock)} />;
    }
    case 'Linksblock': {
      return <LinksSection key={block.id} {...(block as ILinksBlock)} />;
    }
    case 'Bannertextlinks': {
      return (
        <BannerTextLinksSection
          key={block.id}
          {...(block as IBannerTextLinksBlock)}
        />
      );
    }
    case 'Mediatitleblock': {
      return (
        <MediaTitleSection key={block.id} {...(block as IMediaTitleBlock)} />
      );
    }
    case 'Onecolumntextblock': {
      return (
        <OneTextColumnSection
          key={block.id}
          {...(block as IOneColumnTextBlock)}
        />
      );
    }
    case 'Dividerblock': {
      return <DividerSection key={block.id} {...(block as IDividerBlock)} />;
    }
    case 'Textimagesplitscreenblock': {
      return (
        <TextImageSplitScreen
          key={block.id}
          {...(block as ITextImageSplitScreenBlock)}
        />
      );
    }
    case 'Textimageinframeblock': {
      return (
        <TextImageInFrame
          key={block.id}
          {...(block as ITextImageInFrameBlock)}
        />
      );
    }
    case 'Publicationsgridblock': {
      return (
        <PublicationsGrid
          key={block.id}
          {...(block as IPublicationsGridBlock)}
        />
      );
    }
    case 'Pagetitleblock': {
      return <PageTitleBlock key={block.id} {...(block as IPageTitleBlock)} />;
    }
    case 'Publicationssliderblock': {
      return (
        <PublicationsSliderSection
          key={block.id}
          {...(block as IPublicationsSliderBlock)}
        />
      );
    }
    case 'Locationsblock': {
      return (
        <StoreLocations key={block.id} {...(block as IStoreLocationsBlock)} />
      );
    }
    case 'Contactsblock': {
      return <ContactsBlock key={block.id} {...(block as IContactsBlock)} />;
    }
    case 'Faqblock': {
      return <FaqBlock key={block.id} {...(block as IFaqBlock)} />;
    }
    case 'Roundimagelistblock': {
      return (
        <RoundImageList key={block.id} {...(block as IRoundImageListBlock)} />
      );
    }
    case 'Twocolumnstextblock': {
      return (
        <TextTwoColumns key={block.id} {...(block as ITwoColumnsTextBlock)} />
      );
    }
    case 'Photogalleryblock': {
      return (
        <PhotoGalleryBlock key={block.id} {...(block as IPhotoGalleryBlock)} />
      );
    }
    case 'Imagetitlehalfhalf': {
      return (
        <ImageColorTitleHalfHalf
          key={block.id}
          {...(block as IImageTitleHalfHalfBlock)}
        />
      );
    }
    case 'Exclusivesectionsslider': {
      return (
        <ExclusiveSectionsSlider
          key={block.id}
          {...(block as IExclusiveSectionsSlider)}
        />
      );
    }
    case 'Htmlblock': {
      return <HtmlBlock key={block.id} {...(block as IHtmlBlock)} />;
    }
    case 'Warehousecertsblock': {
      return (
        <WarehouseCertsBlock
          key={block.id}
          {...(block as IWarehouseCertsBlock)}
        />
      );
    }
    case 'Contactsminimap': {
      return (
        <ContactsMiniMapBlock
          key={block.id}
          {...(block as IContactsMiniMapBlock)}
        />
      );
    }
    case 'Publicationscatalogsblock': {
      return (
        <PublicationsCatalogsSection
          key={block.id}
          {...(block as IPublicationsCatalogsBlock)}
        />
      );
    }
    case 'Publicationscatalogssliderblock': {
      return (
        <PublicationsCatalogsSliderSection
          key={block.id}
          {...(block as IPublicationsCatalogsSliderSection)}
        />
      );
    }
    case 'Promoproductsblock': {
      return (
        <PromoProductsSection
          key={block.id}
          {...(block as IPromoProductsBlock)}
        />
      );
    }
    case 'Adstabsblock': {
      return <AdsTabs key={block.id} {...(block as IAdsTabsBlock)} />;
    }
    case 'Adscontactsblock': {
      return (
        <AdsContactsBlock key={block.id} {...(block as IAdsContactsBlock)} />
      );
    }

    default: {
      // @ts-ignore
      return <div key={block.id}>{block['__typename']}</div>;
    }
  }
};
